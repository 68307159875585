@use "./../variables/weight" as fw;
@use "./../variables/breakpoints" as *;

$display-big-font-family: "Poppins", sans-serif;
$display-small-font-family: 'Overpass', sans-serif;

.display {

    &-1,
    &-2,
    &-3,
    &-4,
    &-5,
    &-6 {
        margin-bottom: 0;
        letter-spacing: -0.0125rem;

        font: {
            family: $display-big-font-family;
            weight: fw.$semi-bold;
        }
    }

    &-1 {
        font-size: clamp(2.5rem, 1.5714285714285714rem + 3.8095238095238098vw, 5rem);
        line-height: clamp(2.5rem, 1.3857142857142857rem + 4.571428571428571vw, 5.5rem);
    }

    &-2 {
        font-size: clamp(2rem, 1.0714285714285714rem + 3.8095238095238098vw, 4.5rem);
        line-height: clamp(2.5rem, 1.5714285714285714rem + 3.8095238095238098vw, 5rem);
    }

    &-3 {
        font-size: clamp(1.5rem, 0.9428571428571428rem + 2.2857142857142856vw, 3rem);
        line-height: clamp(1.875rem, 1.2714285714285714rem + 2.4761904761904763vw, 3.5rem);
    }

    &-4 {
        font-size: clamp(1.25rem, 0.7857142857142857rem + 1.9047619047619049vw, 2.5rem);
        line-height: clamp(1.75rem, 1.2857142857142856rem + 1.9047619047619049vw, 3rem);
    }

    &-5 {
        font-size: clamp(1.125rem, 0.8rem + 1.3333333333333333vw, 2rem);
        line-height: clamp(1.625rem, 1.3rem + 1.3333333333333333vw, 2.5rem);
    }

    &-6 {
        font-size: clamp(1rem, 0.8142857142857143rem + 0.7619047619047619vw, 1.5rem);
        line-height: clamp(1.375rem, 1.1892857142857143rem + 0.7619047619047619vw, 1.875rem);
    }
}