@use './../custom/colors' as c;
@use './../variables/weight' as fw;
@use './../variables/font-family' as *;
@use './../variables/breakpoints' as *;

$card-font-family: map-get($font-family, 1);

$card-body-margin-top: 1.25rem;
$card-body-border-top-size: null;
$card-body-border-top-color: map-get(c.$default-colors, #{"black"});
$card-body-color: map-get(c.$default-colors, #{"black"});
$card-body-gap: 0.5rem;

$card-title-font-size: 1.125rem;
$card-title-font-weight: fw.$medium;

$card-subtitle-font-size: 0.875rem;
$card-subtitle-font-weight: fw.$semi-bold;
$card-subtitle-opacity: 0.5;

// card inverted
$card-inverted-body-border-color: map-get(c.$default-colors, #{white});
$card-inverted-title-color: map-get(c.$default-colors, #{white});
$card-inverted-subtitle-color: map-get(c.$default-colors, #{white});
$card-inverted-separator-bg-color: map-get(c.$default-colors, #{white});

// card external link
$card-external-link-size: 1.25rem;
$card-external-link-color: map-get(c.$default-colors, #{white});
$card-external-link-font-size: 0.875rem;
$card-external-link-font-weight: fw.$bold;
$card-external-link-hover-color: map-get(c.$default-colors, #{white});

// card separator
$card-separator-bg-color: map-get(c.$default-colors, #{black});
$card-separator-size: 1px;

// card flush
$card-flush-title-font-family: map-get($font-family, 2);

// card border
$card-border-hover-color: map-get(c.$default-colors, #{white});
$card-border-hover-bg-color: map-get(c.$main-colors, primary);

// card purple
$card-purple-bg-color: map-get(c.$main-colors, primary);
;
$card-purple-color: map-get(c.$default-colors, #{white});
;


.card {
    --bs-card-border-radius: 0;
    --bs-card-inner-border-radius: 0;

    --bs-card-border-width: 0;
    --bs-card-spacer-y: 0.5rem;
    --bs-card-spacer-x: 0;

    background-color: unset;

    &--inverted {
        .card {
            &-body {
                border-color: $card-inverted-body-border-color;
            }

            &-title {
                color: $card-inverted-title-color;
            }

            &-subtitle {
                color: $card-inverted-subtitle-color;
            }

            &-separator {
                background-color: $card-inverted-separator-bg-color;
            }
        }
    }

    &--flush {
        .card {
            &-body {
                margin: 0;
                border-top: unset;
                padding: unset;
                gap: 1rem;
                text-transform: none;
            }

            &-title {
                font-family: $card-flush-title-font-family;
            }
        }
    }

    &--border {
        padding: 1rem;
        min-height: 16.5625rem;
        height: auto;

        border-top: 1px solid #000;
        border-bottom: 1px solid #000;


        transition-property: background-color, color;
        transition-duration: 0.4s;
        transition-timing-function: ease-in-out;

        * {
            color: inherit;
        }

        @include breakpoint-min-xl {
            min-height: 28.75rem;
        }
    }

    &--purple {
        color: $card-purple-color;
        background-color: $card-purple-bg-color;

        * {
            color: inherit !important;
        }
    }

    &-overlap {
        display: grid;

        >.card {
            position: static !important;
            grid-area: 1/1;
        }
    }

    &-separator {
        &:not(.card-separator--animation) {
            width: 100%;
        }

        width: 0;
        height: $card-separator-size;

        margin: 0;
        background-color: $card-separator-bg-color;



        @if not($card-body-border-top-size) {
            margin-bottom: 0.5rem;
        }
    }

    &-external-link {
        position: absolute;

        text-decoration: underline;
        color: $card-external-link-color;
        font-size: $card-external-link-font-size;
        font-weight: $card-external-link-font-weight;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: $card-external-link-hover-color;
        }

        &--top {
            right: 0;
            top: 0;

            width: $card-external-link-size;
            height: $card-external-link-size;
        }

        &--bottom {
            bottom: 0;
            left: 0;

            padding: 0.75rem 1rem;

            transition: transform 0.2s, opacity 0.2s;
            transition-timing-function: ease-in-out;

            transform: translateY(100%);
            opacity: 0;
        }
    }

    &-body {
        color: $card-body-color;

        margin-top: $card-body-margin-top;

        @if($card-body-border-top-size) {
            border-top: $card-body-border-top-size solid $card-body-border-top-color;
        }

        @else {
            padding-top: 0;
        }

        text-transform: uppercase;

        display: flex;
        flex-direction: column;
        gap: $card-body-gap;
    }

    &-title {
        line-height: 1;

        font: {
            family: $card-font-family;
            size: $card-title-font-size;
            weight: $card-title-font-weight;
        }
    }

    &-subtitle {
        margin: 0;
        opacity: $card-subtitle-opacity;

        font: {
            family: $card-font-family;
            size: $card-subtitle-font-size;
            weight: $card-subtitle-font-weight;
        }
    }

    // wrapper for clip-path animation
    &-frame {
        display: grid;
        position: relative;
        overflow: hidden;

        &.frame--rectangle {

            .card-img-top,
            .card-img-bottom {
                aspect-ratio: 9/11;
            }
        }

        >* {
            grid-area: 1 / 1;
        }

        .card-img {

            &-top,
            &-bottom {
                object: {
                    fit: cover;
                    position: center;
                }
            }
        }

        &:hover {
            .card-external-link--bottom {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

// test
.circle-blend {
    border-radius: 9999px;
    width: 120%;
    height: 120%;

    aspect-ratio: 1;

    background: map-get(c.$main-colors, primary);

    position: absolute;
    mix-blend-mode: multiply;

    opacity: 0;
    filter: blur(90px);
}


.swiper-slide {
    &:not(:last-child) {
        .card--flush {
            border-right: 1px solid #000;
        }
    }
}