@use './../custom/colors' as c;

// * General
$form-check-input-size: 18px;
$form-check-input-border: 1px solid map-get(c.$default-colors, #{black});

// * Checkbox
$form-check-input-border-radius: 0.05em;

// * Checked
$form-check-input-checked-bg-color: map-get(c.$default-colors, #{black});

// ** Label
$form-checked-label-font-size: 0.875rem;


.form-check {
    &-input {
        width: $form-check-input-size;
        height: $form-check-input-size;

        border: $form-check-input-border;

        &[type="checkbox"] {
            border-radius: $form-check-input-border-radius;
        }

        &:checked {
            background-color: $form-check-input-checked-bg-color;
        }

        &:focus {
            box-shadow: unset;
        }
    }

    &-label {
        font-size: $form-checked-label-font-size;
    }
}