.fixed-parallax-video {
    margin: 0 !important;
    background: transparent !important;

    min-height: 70vh;
    pointer-events: none;

    position: relative;
    z-index: -1;

    img,
    video {
        min-height: 100vh;
        object-fit: cover;
        object-position: center;

        display: none;

        position: absolute;
        inset: 0;
    }

    &.video-start {

        img,
        video {
            position: fixed;
            display: block;
        }
    }
}