body {

    &.gradient {
        &-w-b {
            background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
        }
    }

    // Menu open
    &.menu-active {
        overflow: hidden !important;

        .header--left {
            &:not(.fixed) {
                opacity: 0;
                pointer-events: unset;
            }

            &.fixed {
                opacity: 1;
                pointer-events: unset;
                transition: opacity 0.4s ease-in-out 0.4s;
            }
        }

        // logo when menu is open
        .logo {
            fill: #fff !important;
        }
    }
}