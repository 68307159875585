@use "./../variables/weight" as fw;
@use "./../custom/colors" as c;

$badge-bold-color: map-get(c.$default-colors, #{white});
$badge-bold-bg-color: map-get(c.$default-colors, #{black});
$badge-bold-padding-y: 0.75rem;
$badge-bold-padding-x: 1.25rem;

.badge {
	--badge-padding-block: 0.5rem;
	--badge-padding-inline: 3rem;

	--bs-badge-color: var(--dark);

	font-size: 1rem;
	font-weight: fw.$regular;

	text-transform: uppercase;

	padding: 0;
	// margin-block-end: 1.8rem;

	padding-block: var(--badge-padding-block);
	// padding-inline-end: var(--badge-padding-inline);

	color: inherit;
	border-bottom: 1px solid currentColor;
	border-radius: 0;

	margin-bottom: 1.5rem;

	&--bold {
		color: $badge-bold-color;
		background-color: $badge-bold-bg-color;

		border: unset;

		padding: $badge-bold-padding-y $badge-bold-padding-x;
	}

	&.badge-center {
		padding-inline: calc(var(--badge-padding-inline) / 2);
	}

	&.badge-right {
		padding-inline-start: var(--badge-padding-inline);
		padding-inline-end: 0;
	}
}