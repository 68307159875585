@use "./../variables/breakpoints" as *;
@use "./../custom/colors" as c;

// scrollbar
$custom-scrollbar: false;
$custom-scrollbar-width: 6px;
$custom-scrollbar-border-radius: 5px;

:root {

	@each $color,
	$value in c.$main-colors {
		--#{$color}: #{$value};
		--#{$color}-dark: #{darken($value, 15)};
		--#{$color}-darken: #{darken($value, 20)};
	}

	@each $color,
	$value in c.$default-colors {
		--#{$color}: #{$value};
	}
}

// scrollbar
@if ($custom-scrollbar) {
	*::-webkit-scrollbar {
		width: $custom-scrollbar-widsth;
		background: none;
	}

	*::-webkit-scrollbar-track {
		background-color: #f3f4f7;
		margin: 0;
	}

	*::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.25);
		border-radius: $custom-scrollbar-border-radius;
	}

	*::-webkit-scrollbar-thumb:hover {
		background-color: var(--primary);
	}
}