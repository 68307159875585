@use './../variables/weight' as fw;

$form-floatin-height: 2.5rem;

$form-floatin-control-padding-x: 0;
$form-floatin-control-padding-y: 0.3125rem;

$form-floating-label-font-weight: fw.$light;

.form-floating {
    >.form-control {
        height: $form-floatin-height;
    }

    >.form-control,
    >.form-control-plaintext {
        padding: {
            left: $form-floatin-control-padding-x;
            right: $form-floatin-control-padding-x;

            top: $form-floatin-control-padding-y;
            bottom: $form-floatin-control-padding-y;
        }
    }

    >label {
        font-size: 0.875rem;
        text-transform: uppercase;
        font-weight: $form-floating-label-font-weight;

        padding: {
            top: 0.875rem;
            bottom: 0.5625rem;
            left: 0;
            right: 0;
        }
    }


}

.form-floating>.form-control-plaintext~label,
.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
    opacity: 1;
    transform: scale(0.65) translateY(-.5rem) translateX(0);
}

.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown),
.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
    padding: {
        bottom: 5px;
        top: 15px;
    }
}