.conv-form-wrapper {
    // font-family: "mynd-font", sans-serif;
}

div.conv-form-wrapper div#messages div.message,
form.convFormDynamic textarea.userInputDynamic {
    // font-size: 1.6rem;
}

form.convFormDynamic,
form.convFormDynamic textarea.userInputDynamic,
form.convFormDynamic button.submit {
    transition: background-color 0.2s;
}

form.convFormDynamic.disablechat,
form.convFormDynamic.disablechat textarea.userInputDynamic,
form.convFormDynamic.disablechat button.submit {
    background-color: #ddd;
}

form.convFormDynamic {
    width: 100%;
    margin: 2.5rem 0 0 0;
}

form.convFormDynamic button.submit {
    overflow: hidden;
    font-size: 0;
}

form.convFormDynamic button.submit:after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuMDM0MDMxOSAyOC41OThMMi41NjgwMyAxNy42MjhDMi42NjgwMyAxNy4xNjIgMy4wNjgwMyAxNi43OTUgMy41NjgwMyAxNi43MjhMMTcuNzM4IDE1LjI2MUMxOC4xMzggMTUuMjI4IDE4LjEzOCAxNC42MjggMTcuNzM4IDE0LjU2MUwzLjU2ODAzIDEzLjE5NEMzLjA2ODAzIDEzLjE2MSAyLjY2ODAzIDEyLjc5NCAyLjU2ODAzIDEyLjMyN0wwLjAzNDAzMTkgMS4zOTFDLTAuMTk4OTY4IDAuNDU4MDA0IDAuODAxMDMyIC0wLjMwODk5NiAxLjY2ODAzIDAuMTI0MDA0TDI5LjM3NSAxMy45OTRDMzAuMjA4IDE0LjQyOCAzMC4yMDggMTUuNjI4IDI5LjM3NSAxNi4wNjJMMS42NjgwMyAyOS44NjVDMC44MDEwMzIgMzAuMjk4IC0wLjE5ODk2OCAyOS41MzEgMC4wMzQwMzE5IDI4LjU5OFoiIGZpbGw9IiNENTAwNTgiLz4KPC9zdmc+Cg==+PHBhdGggZD0iTS4wMzQgMjguNTk4bDIuNTM0LTEwLjk3Yy4xLS40NjYuNS0uODMzIDEtLjlsMTQuMTctMS40NjdjLjQtLjAzMy40LS42MzMgMC0uN2wtMTQuMTctMS4zNjdjLS41LS4wMzMtLjktLjQtMS0uODY3TC4wMzQgMS4zOTFDLS4xOTkuNDU4LjgwMS0uMzA5IDEuNjY4LjEyNGwyNy43MDcgMTMuODdjLjgzMy40MzQuODMzIDEuNjM0IDAgMi4wNjhMMS42NjggMjkuODY1Yy0uODY3LjQzMy0xLjg2Ny0uMzM0LTEuNjM0LTEuMjY3eiIgZmlsbD0iI2EwMjI0YSIvPjwvc3ZnPg==");
    width: 30px;
    height: 30px;
    display: block;
    opacity: 0.7;
    transition: opacity 0.25s;
}

form.convFormDynamic button.submit:hover {
    background: none;
}

form.convFormDynamic button.submit:hover:after {
    opacity: 1;
}

div.conv-form-wrapper div#messages {
    padding-bottom: 0;
}

div.conv-form-wrapper div#messages div.message.from {
    background: #d50058;
}

div.conv-form-wrapper div.options {
    transform: translateY(-18px);
}

div.conv-form-wrapper div.options div.option {
    // padding: 0.7rem 1rem;
    border: 1px solid #000;
    display: inline-block;
    margin: 0 1rem 0 0;
    color: #000;
    cursor: pointer;
    border-radius: 20px;
    // font-size: 1.4rem;
    text-transform: uppercase;

    transition: {
        property: background-color, color, border-color;
        duration: 0.2s;
        timing-function: ease-in-out;
    }

    &:hover {
        border-color: #d50058;
        background-color: #d50058;
        color: #fff;
    }
}

div.conv-form-wrapper div.options div.option:hover {
    color: #fff;
    background: #d50058;
}

div.conv-form-wrapper:before {
    display: none;
}

.spinLoader {
    opacity: 1;
    transition: opacity 0.3s;
    color: #d50058 !important;
    background: #d50058 !important;
}

.spinLoader:before,
.spinLoader:after {
    background: #d50058 !important;
}

.spinLoader.hidden {
    opacity: 0;
}