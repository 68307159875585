@use './../custom/colors' as c;
@use './../variables/breakpoints' as *;

// megamenu
$megamenu-padding-top: 8rem;
$megamenu-padding-bottom: 1.5rem;
$megamenu-z-index: 999;

$megamenu-bg-color: map-get(c.$default-colors, #{black});
$megamenu-color: map-get(c.$default-colors, #{white});

$megamenu-md-padding-y: 1.5rem;

// megamenu container
$megamenu-container-max-width: 70.5rem;

// megamenu body
$megamenu-body-scrollbar-track: rgba(map-get(c.$default-colors, #{white}), 0.2);
$megamenu-body-scrollbar-handle: rgba(map-get(c.$default-colors, #{white}), 0.8);
$megamenu-body-scrollbar-handle-hover: map-get(c.$default-colors, #{white});

// megamenu footer
$megamenu-footer-border-color: rgba(map-get(c.$default-colors, #{white}), 0.6);
$megamenu-footer-spacing: 1.5rem;
$megamenu-footer-md-padding-top: 1.5rem;

.megamenu {
    width: 100%;
    height: 100%;

    position: fixed;
    inset: 0;
    z-index: $megamenu-z-index;

    padding-top: $megamenu-padding-top;
    padding-bottom: $megamenu-padding-bottom;

    color: $megamenu-color;
    background-color: $megamenu-bg-color;

    transition: 0.5s ease-in-out;
    transform: translateX(100%);

    @include breakpoint-min-md {
        padding: {
            top: $megamenu-md-padding-y;
            bottom: $megamenu-md-padding-y;
        }
    }

    &.active {
        transform: translateX(0);

        .megamenu {

            &__body,
            &__footer {
                opacity: 1;
            }
        }
    }

    &__container {
        padding-inline: 1.5rem;
        height: 100%;

        display: flex;
        flex-direction: column;

        max-width: calc($megamenu-container-max-width + calc(1.5rem * 2));
        margin-inline: auto;
    }

    &__body,
    &__footer {
        opacity: 0;
        transition: opacity 0.5s ease-in-out 0.5s;
    }

    &__body {
        $megamenu-body-margin-inline: 1rem;
        margin-block: auto;

        @include breakpoint-max-md {
            overflow-y: auto;
        }

        // custom scrollbar
        &::-webkit-scrollbar {
            // width
            width: 0.25rem;
            transition: 0.2s ease-in-out;

            // track
            &-track {
                background: $megamenu-body-scrollbar-track;
            }

            // handle
            &-thumb {
                background: $megamenu-body-scrollbar-handle;
                border-radius: 9999px;

                &:hover {
                    background: $megamenu-body-scrollbar-handle-hover;
                }
            }
        }

        /* @include breakpoint-min-md {
            padding-bottom: 3rem;
        } */

        &-container {
            margin-inline: -#{$megamenu-body-margin-inline};
        }
    }

    &__footer {
        display: grid;
        grid-template-columns: 1fr;
        gap: $megamenu-footer-spacing;


        padding-top: $megamenu-footer-spacing;

        @include breakpoint-min-md {
            // flex-direction: row;
            // justify-content: space-between;

            padding-top: $megamenu-footer-md-padding-top;
        }

        &--left {
            order: 3;
        }

        &--separator {
            order: 2;

            hr {
                margin-block: 0;
                border-color: $megamenu-footer-border-color;
                opacity: 1;
            }
        }

        &--right {
            order: 1;

            display: flex;
            justify-content: center;
            padding-bottom: 1rem;

            @include breakpoint-min-md {
                padding-bottom: 0;
            }
        }
    }
}