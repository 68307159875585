@use './../custom/colors' as c;

$main-bg-color: map-get(c.$default-colors, #{white});

main {
    background-color: $main-bg-color;
}

.main-container {
    position: relative;
    z-index: 2;

    background-color: $main-bg-color;
}