@use './../custom/colors' as c;

.btn-contact {
    width: 6rem;
    height: 6rem;
    border-radius: 3rem;
    border-bottom-right-radius: 0;
    position: relative;
    transition: all 0.3s;
    cursor: pointer;

    display: grid;
    place-items: center;

    &.in-footer {
        bottom: 9rem !important;

        @media (min-width: 768px) {
            bottom: 5rem !important;
        }
    }
}

.btn-contact:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAyNCAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3LjEzMSAwTDExLjk1OTcgMTEuODkwMkw2LjgwNjYzIDBIMEw4LjU0NTk5IDE5Ljc2MTlMNC4xMjA5IDMwSDEwLjk0ODNMMjQgMEgxNy4xMzFaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K);
    /* position: absolute;
    top: 50%;
    left: 50%; */
    // transform: translate(-50%, -50%) scale(0.3);
    // height: 95px;
    // width: 67px;
    opacity: 0.87;
    height: 1.875rem;
}

#btn-contact {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 4rem;
    background: map-get(c.$main-colors, primary);
    border: 4px solid #fff;


    // box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.17);
    margin-top: 0;
    position: fixed;
    // transform: translateX(150%);
    z-index: 4;
    bottom: 2rem;
    right: 2rem;
}

#btn-contact:after {
    // transform: translate(-50%, -50%) scale(0.4);
    opacity: 1;
}

@media (min-width: 768px) {
    #btn-contact:after {
        // transform: translate(-50%, -50%) scale(0.45);
    }
}

@media (min-width: 1200px) {
    #btn-contact:after {
        // transform: translate(-50%, -50%) scale(0.55);
    }
}

#btn-contact.active {
    transform: translateX(0);
    z-index: 4;
}

#btn-contact.bottom {
    transform: translateX(150%);
    transition: transform 0.3s;
}

#btn-contact.active:hover {
    bottom: 3rem;
}

.hp #btn-contact.bottom:after {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2NyIgaGVpZ2h0PSI5NSI+PHBhdGggZD0iTTU3IDBMMzUuNCA0OS42Yy0uNCAxLjEtMS42IDEtMS42IDEtMS4yIDAtMS43LS45LTEuOC0xTDkuOCAwSC40bDI3IDYwLjUuMS4zYy40LjggMS4xIDMuNi0uNiA4LjFMMTUuNiA5NWg5LjVMNjYuNiAwSDU3eiIgZmlsbD0iIzczMjQzZCIvPjwvc3ZnPg==);
    transform: translate(-50%, -50%) scale(0.5);
}

.hp #btn-contact.bottom {
    width: 10rem;
    height: 10rem;
    background: #fff;
    border-radius: 5rem;
    border-bottom-right-radius: 0 !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4.5rem;
    background: #fff;
    transition: width 0.3s, height 0.3s, right 0.3s, background 0.3s;
}

@media (min-width: 576px) {
    .hp #btn-contact.bottom {
        width: 12rem;
        height: 12rem;
        border-radius: 6rem;
    }

    .hp #btn-contact.bottom:after {
        transform: translate(-50%, -50%) scale(0.7);
    }
}

@media (min-width: 768px) {
    .hp #btn-contact.bottom {
        width: 13rem;
        height: 13rem;
        border-radius: 6.5rem;
    }

    .hp #btn-contact.bottom:after {
        transform: translate(-50%, -50%) scale(0.8);
    }
}

@media (min-width: 992px) {
    .hp #btn-contact.bottom {
        width: 15rem;
        height: 15rem;
        border-radius: 7.5rem;
    }

    .hp #btn-contact.bottom:after {
        transform: translate(-50%, -50%) scale(0.9);
    }
}

@media (min-width: 1200px) {
    .hp #btn-contact.bottom {
        width: 16rem;
        height: 16rem;
        border-radius: 8rem;
    }

    .hp #btn-contact.bottom:after {
        transform: translate(-50%, -50%) scale(1);
    }
}