@use './../custom/colors' as c;
@use './../variables/breakpoints' as *;

$footer-padding-bottom: 1.5rem;
$footer-spacing: 2rem;
$footer-md-spacing: 7.5rem;
$footer-font-size: 0.875rem;

$footer-color: map-get(c.$default-colors, #{white});
$footer-bg-color: map-get(c.$default-colors, #{black});

$footer-copyright-font-size: 0.75rem;
$footer-copyright-border-color: rgba(map-get(c.$default-colors, #{white}), 0.6);
$footer-copyright-padding-top: $footer-padding-bottom;

.footer {
    z-index: -1;

    position: absolute;
    bottom: 0;
    left: -100%;
    right: unset;

    color: $footer-color;
    background-color: $footer-bg-color;

    display: flex;
    flex-direction: column;
    gap: $footer-spacing;

    font-size: $footer-font-size;

    padding: {
        top: $footer-spacing;
        bottom: $footer-padding-bottom;
    }

    // display: none;

    &.visible {
        // display: block;
        position: fixed;

        left: 0;
        right: 0;

        z-index: 1;
    }

    @include breakpoint-min-md {
        gap: $footer-md-spacing;
        padding-top: $footer-md-spacing;
    }

    &__container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;

        @include breakpoint-min-md {
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: 2rem; // logo height   
        }

        @include breakpoint-min-lg {
            grid-template-columns: auto auto 2fr 1fr 1fr;
            grid-template-rows: 4rem; // address height
            column-gap: 3rem;
        }

        @include breakpoint-min-xl {
            grid-template-columns: auto auto 3fr 1fr 1fr;
        }
    }

    &__social {
        display: flex;
        gap: 1rem;

        @include breakpoint-max-md {
            justify-content: center;

        }

        @include breakpoint-min-md {
            order: 2;
            grid-area: auto / 1 / auto / -1;
        }

    }

    &__menu {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        @include breakpoint-max-md {
            margin-block: 2rem;
        }

        @include breakpoint-min-md {
            grid-area: 1 / 2 / 1 / -1;
        }

        @include breakpoint-min-lg {
            grid-area: 1 / 4 / 1 / -1;
        }
    }

    &_address {
        @include breakpoint-min-md {
            grid-area: auto / 1 / auto / 1;
        }

        @include breakpoint-min-lg {
            grid-area: auto / 2 / auto / 2;
        }
    }

    &__info {
        @include breakpoint-min-md {
            grid-area: auto / 1 / auto / 1;
        }
    }

    &__iva {
        display: flex;
        align-items: center;

        @include breakpoint-min-md {
            flex-direction: column;
            align-items: flex-start;

            grid-area: auto / 1 / auto / 1;
        }

        @include breakpoint-min-lg {
            grid-area: auto / 2 / auto / 2;
        }

        @include breakpoint-max-md {
            gap: 0.5rem;

            >* {
                display: flex;
                gap: 0.5rem;
            }

            > :not(:first-child) {
                &:before {
                    content: '—';
                }
            }
        }
    }

    &__copyright {
        border-top: 1px solid $footer-copyright-border-color;
        padding-top: $footer-copyright-padding-top;

        display: flex;
        flex-direction: column;
        gap: 2rem;

        @include breakpoint-min-md {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }
}

.copyright {
    &__cookie {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;

        font-size: $footer-copyright-font-size;

        @include breakpoint-min-md {
            gap: 3.25rem;
        }
    }
}