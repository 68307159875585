@use './../variables/breakpoints' as *;

section {
    .section-innovation {
        display: flex;
        flex-direction: column;
        justify-content: center;

        >.container {
            display: flex;
            flex-direction: column;
            gap: clamp(2rem, 0.3285714285714285rem + 6.857142857142858vw, 6.5rem);

            @include breakpoint-min-lg {
                gap: 12.5rem;
            }
        }
    }
}