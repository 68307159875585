@use './../custom/colors' as c;
@use './../variables/breakpoints' as *;
@use './../variables/weight' as fw;
@use './../variables/font-family' as *;

// accordion
$custom-accordion-border: 1px solid map-get(c.$default-colors, #{black});

// btn
$custom-accordion-button-padding-y: 0.75rem;
$custom-accordion-button-padding-x: 1.5rem;
$custom-accordion-button-gap: 1.25rem;
$custom-accordion-button-font-size: 1rem;
$custom-accordion-button-font-weight: fw.$semi-bold;
$custom-accordion-button-expanded-color: map-get(c.$default-colors, #{white});
$custom-accordion-button-expanded-bg-color: map-get(c.$main-colors, primary);

// body
$custom-accordion-body-padding: 1.5rem;
$custom-accordion-body-padding-bottom: 2.5rem;

// list
$custom-accordion-list-border: 1px solid rgba(map-get(c.$default-colors, #{black}), 0.25);
$custom-accordion-list-font-size: 0.875rem;
$custom-accordion-list-line-height: 1.25rem;
$custom-accordion-list-padding: 0.5rem 1rem;

// 992px accordion desktop
$custom-accordion-lg-column: 420px;

// accordion desktop - body
$custom-accordion-body-lg-padding: 1.5rem 3.5rem 2.5rem;
$custom-accordion-body-lg-gap: 2rem;
$custom-accordion-body-lg-height: 492px;

// accordion desktop - button
$custom-accordion-btn-lg-font-size: 1.25rem;
$custom-accordion-btn-lg-padding: 0.5rem;
$custom-accordion-btn-lg-padding-left: 3.5rem;

// accordion desktop - list
$custom-accordion-list-lg-gap: 2rem;
$custom-accordion-list-lg-padding-right: 0.5rem;
$custom-accordion-list-lg-font-size: 1rem;
$custom-accordion-list-lg-padding: 0.8rem 0;

$custom-accordion-list-lg-scrollbar-width: 0.25rem;

$custom-accordion-list-lg-scrollbar-thumb-bg: map-get(c.$main-colors, primary);
$custom-accordion-list-lg-scrollbar-thumb-border-radius: 9999px;
$custom-accordion-list-lg-scrollbar-hover-thumb-bg: darken(map-get(c.$main-colors, primary), 10%);

// accordion--BIG
$custom-accordion-big-lg-column: 583px;
$custom-accoridon-big-lg-btn-font-size: clamp(1.25rem, 1.0178571428571428rem + 0.9523809523809524vw, 1.875rem);
$custom-accoridon-big-lg-btn-padding: 2rem 0.5rem;
$custom-accoridon-big-lg-btn-padding-left: 3.5rem;

$custom-accordion-big-lg-body-height: 400px;

// accordion--Alternative
$custom-accordion-alt-btn-color: rgba(map-get(c.$default-colors, #{black}), 0.5);
$custom-accordion-alt-btn-font-weight: fw.$medium;

$custom-accordion-alt-btn-hover-bg-color: rgba(map-get(c.$default-colors, #{black}), 8%);
$custom-accordion-alt-btn-collapsed-color: map-get(c.$main-colors, primary);
$custom-accordion-alt-btn-collapsed-bg-color: map-get(c.$default-colors, #{black});

$custom-accoridon-alt-btn-number-font-size: 1rem;
$custom-accoridon-alt-btn-number-font-family: map-get($font-family, 1);
$custom-accoridon-alt-btn-number-font-weight: fw.$light;
$custom-accoridon-alt-btn-number-font-style: italic;

$custom-accordion-alt-body-bg-color: null;

// * Accordion Different style - Huge
// $accordion-huge: ;

.accordion {
    border: {
        top: $custom-accordion-border;
        bottom: $custom-accordion-border;
    }

    // reset
    &-item {
        border: 0;
        background-color: unset;
    }

    &-button {
        // reset
        border-radius: 0 !important;
        box-shadow: unset !important;
        outline: 0 !important;
        border: 0;
        background-color: transparent;

        padding: $custom-accordion-button-padding-y $custom-accordion-button-padding-x;
        gap: $custom-accordion-button-gap;

        font: {
            size: $custom-accordion-button-font-size;
            weight: $custom-accordion-button-font-weight;
        }

        &:not(.collapsed) {
            color: $custom-accordion-button-expanded-color;
            background-color: $custom-accordion-button-expanded-bg-color;
        }

        &:before {
            content: '—';
        }

        //icon
        &::after {
            content: "\e5cf";
            font-family: 'Material Symbols Outlined';

            font-weight: inherit;
            font-size: 1.5rem;

            // reset
            background: unset !important;
            width: unset;
            height: unset;
        }
    }

    &-logo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.5rem 3.5rem;
    }

    &-list {
        // reset
        padding: 0;
        margin: 0;
        list-style: none;

        li {
            font-size: $custom-accordion-list-font-size;
            line-height: $custom-accordion-list-line-height;
            padding: $custom-accordion-list-padding;
            border-bottom: $custom-accordion-list-border;
        }

        &:first-child li:first-child {
            border-top: $custom-accordion-list-border;
        }
    }

    &-body {
        padding: $custom-accordion-body-padding;
        padding-bottom: $custom-accordion-body-padding-bottom;

        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        overflow-y: auto;
    }

    // 992px js
    &.accordion-grid {
        display: grid;
        grid-template-columns: $custom-accordion-lg-column 1fr;

        // generated via js
        .name-container {
            border-right: $custom-accordion-border;
        }

        // generated via js
        .collapse-container {
            overflow: hidden;
            display: grid;

            >* {
                grid-area: 1/1;
            }
        }

        .accordion {
            &-body {
                padding: $custom-accordion-body-lg-padding;
                gap: $custom-accordion-body-lg-gap;
                height: $custom-accordion-body-lg-height;
            }

            &-collapse {
                height: 100% !important;
                // transform: translateX(-100%);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;

                &.show {
                    // transform: translateX(0);
                    opacity: 1;
                }
            }

            &-button {
                font-size: $custom-accordion-btn-lg-font-size;

                padding: $custom-accordion-btn-lg-padding;
                padding-left: $custom-accordion-btn-lg-padding-left;

                &:after {
                    transform: rotate(-90deg) !important;
                }
            }

            &-list {
                li {
                    font-size: $custom-accordion-list-lg-font-size;
                    padding: $custom-accordion-list-lg-padding;

                    &:first-child {
                        border-top: $custom-accordion-list-border;
                    }
                }

                &-wrapper {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: $custom-accordion-list-lg-gap;

                    padding-right: $custom-accordion-list-lg-padding-right;

                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: $custom-accordion-list-lg-scrollbar-width;

                        &-thumb {
                            background: $custom-accordion-list-lg-scrollbar-thumb-bg;
                            border-radius: $custom-accordion-list-lg-scrollbar-thumb-border-radius;

                            &:hover {
                                background: $custom-accordion-list-lg-scrollbar-hover-thumb-bg;
                            }
                        }
                    }
                }
            }
        }
    }

    // accordion - Alternative
    &.accordion--alt {
        .accordion {
            &-button {
                color: $custom-accordion-alt-btn-color;
                font-weight: $custom-accordion-alt-btn-font-weight;

                &:hover {
                    background-color: $custom-accordion-alt-btn-hover-bg-color;
                }

                &::before {
                    content: unset;
                }

                &:not(.collapsed) {
                    color: $custom-accordion-alt-btn-collapsed-color;
                    background-color: $custom-accordion-alt-btn-collapsed-bg-color;
                }

                span {
                    font: {
                        family: $custom-accoridon-alt-btn-number-font-family;
                        size: $custom-accoridon-alt-btn-number-font-size;
                        weight: $custom-accoridon-alt-btn-number-font-weight;
                        style: $custom-accoridon-alt-btn-number-font-style;
                    }
                }
            }

            &-body {
                background-color: $custom-accordion-alt-body-bg-color;
            }
        }
    }

    // accordion - BIG
    &.accordion--big {
        &.accordion-grid {
            grid-template-columns: $custom-accordion-big-lg-column 1fr;

            .accordion {
                &-button {
                    font-size: $custom-accoridon-big-lg-btn-font-size;
                    padding: $custom-accoridon-big-lg-btn-padding;
                    padding-left: $custom-accoridon-big-lg-btn-padding-left;
                }


                &-body {
                    height: $custom-accordion-big-lg-body-height;
                }
            }
        }

    }

    // * Accordion Huge
    &--huge {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        border: unset;

        .accordion {
            &-item {
                border: 1px solid #000;
                border-radius: 0;
            }

            &-header {
                min-height: 8rem;
            }

            &-button {
                text-transform: uppercase;

                display: grid;
                grid-template-columns: 1fr 1fr auto;
                gap: 0.5rem 1rem;

                &:not(.collapsed) {
                    background-color: transparent;
                    color: map-get(c.$default-colors , #{black});
                }

                &:before {
                    content: unset;
                }

                &:after {
                    content: unset;
                }

                &__plusminus {
                    grid-area: 1 / 3 / 1 / 4;
                }

                &__request {
                    color: rgba(map-get(c.$default-colors, #{black}), 60%);
                    font-size: 0.875rem;

                    display: flex;
                    gap: 0.5rem;
                    align-items: center;
                    grid-area: 1 / 1 / 1 / 3;

                    >*:not(:last-child) {
                        display: flex;
                        gap: 0.5rem;

                        &:after {
                            content: '‧';
                        }
                    }
                }

                &__title {
                    grid-area: 2/ 1/ 3 /4;
                }
            }
        }
    }
}

// * Accordion Plus Icon
.accordion {
    &-button {
        &:not(.collapsed) {
            .accordion-button__plusminus {
                &:before {
                    transform: translatey(-50%) rotate(-90deg);
                    opacity: 0;
                }

                &:after {
                    transform: translatey(-50%) rotate(0);
                }
            }
        }

        &__plusminus {
            position: relative;
            width: 1rem;
            height: 1rem;

            &:before,
            &:after {
                content: "";
                display: block;
                background-color: #000;
                position: absolute;
                top: 50%;
                left: 0;
                transition: .35s;
                width: 100%;
                height: 1px;
            }

            &:before {
                transform: translatey(-50%);
            }

            &:after {
                transform: translatey(-50%) rotate(90deg);
            }

        }
    }
}