.attachment-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    margin-block: 0 !important;

    // min-height: 100vh;

    &.scroll-end {
        position: absolute;
    }

    /* +.main-container {
        margin-top: 200vh;
    } */
}