@use "./../custom/colors" as *;

$has-text-stroke-class: true;

// generate text color
@each $color,
$value in $main-colors {
	.text-#{$color} {
		color: var(--#{$color}) !important;
	}
}

@if $has-text-stroke-class {

	@each $color,
	$value in $main-colors {

		// text stroke
		.text-stroke-#{$color} {
			--stroke-width: 1px;
			color: transparent !important;

			-webkit-text-stroke: var(--stroke-width) var(--#{$color}) !important;
		}
	}
}

.text-between {
	margin-bottom: 0;

	>span {
		display: block;

		&:last-child {
			text-align: right;
		}
	}
}

.text-current {
	color: currentColor !important;
}