@use './../variables/breakpoints' as *;

.masonry-grid {
    .masonry-grid-item {
        width: 100%;

        @include breakpoint-min-md {
            // * Width elmenti - Magic Grid gutter
            width: calc(50% - 100px);
        }
    }
}