@use './../custom/colors' as c;
@use './../variables/breakpoints' as *;

$slider-scrollbar-height: 1px;
$slider-scrollbar-margin-top: 1rem;
$slider-scrollbar-bg-color: rgba(map-get(c.$default-colors, #{black}), 0.1);

$slider-scrollbar-drag-border-radius: 0;
$slider-scrollbar-drag-bg-color: map-get(c.$default-colors, #{black});

$slider-navigation-margin-top: 2.5rem;
$slider-navigation-gap: 1rem;

// slider grid
$slider-grid-md-columns: 3;

$slider-grid-md-gap-x: 2rem;
$slider-grid-md-gap-y: 2rem;

$slider-grid-lg-gap-x: 5rem;
$slider-grid-lg-gap-y: 3rem;

.slider {
    &-scrollbar {
        margin-top: $slider-scrollbar-margin-top;
        height: $slider-scrollbar-height;
        background-color: $slider-scrollbar-bg-color;

        .swiper-scrollbar-drag {
            border-radius: $slider-scrollbar-drag-border-radius;
            background: $slider-scrollbar-drag-bg-color;
        }
    }

    &-overflow {
        overflow: hidden;

        .swiper {
            overflow: unset;
        }
    }

    &-navigation {
        margin-top: $slider-navigation-margin-top;

        display: flex;
        align-items: center;
        gap: $slider-navigation-gap;

        @include breakpoint-min-lg {
            display: none;
        }
    }

    &-md-grid {
        @include breakpoint-min-md {
            .swiper-wrapper {
                display: grid;
                gap: $slider-grid-md-gap-y $slider-grid-md-gap-x;
                grid-template-columns: repeat(#{$slider-grid-md-columns}, 1fr);

                .swiper-slide {
                    width: 100% !important;
                }
            }

            .slider-navigation {
                display: none;
            }
        }

        @include breakpoint-min-lg {
            .swiper-wrapper {
                gap: $slider-grid-lg-gap-y $slider-grid-lg-gap-x;
            }
        }
    }
}

// list client slider
.container-free-mode>.swiper-wrapper {
    transition-timing-function: linear;
}

.client-slider {
    .swiper-slide {
        width: auto !important;
    }

    .logo-carousel {
        object-fit: contain;
    }
}

.slider-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto !important;
}

.swiper {
    &.vantages-wrapper {
        .slider-navigation {
            @include breakpoint-min-md {
                display: none;
            }
        }
    }
}