.word-mask {
    &__container {
        display: inline-block;
        overflow: hidden;
    }

    &__child {
        transform: translateY(100%);
        display: inline-block;
    }
}