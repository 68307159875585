$icomoon-font-family: "mynd" !default;
$icomoon-font-path: "/fonts" !default;

$mynd-full-screen: "\e901";
$mynd-pause: "\e902";
$mynd-play: "\e903";
$mynd-small-screen: "\e904";
$mynd-sound-off: "\e905";
$mynd-sound-on: "\e906";
$mynd-arrow-right: "\e900";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hx5g6z');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hx5g6z#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hx5g6z') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hx5g6z') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hx5g6z##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="mynd-"],
[class*=" mynd-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.mynd-full-screen {
    &:before {
        content: $mynd-full-screen;
    }
}

.mynd-pause {
    &:before {
        content: $mynd-pause;
    }
}

.mynd-play {
    &:before {
        content: $mynd-play;
    }
}

.mynd-small-screen {
    &:before {
        content: $mynd-small-screen;
    }
}

.mynd-sound-off {
    &:before {
        content: $mynd-sound-off;
    }
}

.mynd-sound-on {
    &:before {
        content: $mynd-sound-on;
    }
}

.mynd-arrow-right {
    &:before {
        content: $mynd-arrow-right;
    }
}