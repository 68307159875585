$link-opacity-opacity: 0.6;

a {
    &.link-opacity {
        opacity: $link-opacity-opacity;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }

    &.link-underline {
        text-decoration: underline;
    }
}