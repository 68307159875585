@use "./../variables/breakpoints" as *;

// width custom
.w-custom {
	--width: 100%;
	width: var(--width) !important;
}

@each $media,
$value in $media-queries {
	@media (min-width: $value) {
		.w-#{$media}-custom {
			--width-#{$media}: 100%;
			width: var(--width-#{#{$media}
			}) !important;
	}
}
}

// * Video come-lo-facciamo.html
.horizontal-video {
	@include breakpoint-min-xl {
		width: 5rem;
	}
}