@use './../variables/weight' as fw;
@use './../custom/colors' as c;

// focus
$form-control-focus-border-color: map-get(c.$default-colors, #{black});
$form-control-font-weight: fw.$light;

.form {
    &-select {
        padding-left: 0;
        border-radius: 0;
        border-width: 0 0 1px 0;
        text-transform: uppercase;

        font-size: 0.875rem;
        font-weight: $form-control-font-weight;

        &:focus {
            box-shadow: unset;
            border-color: $form-control-focus-border-color;
        }
    }
}