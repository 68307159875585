@use "./../variables/breakpoints" as *;
@use "./../custom/colors" as c;
@use "./../components/megamenu" as megamenu;

$header-position-y: 1.5rem;
$header-position-x: 1.5rem;

$header-md-position-y: 2rem;
$header-md-position-x: 3rem;

$header-height: 3rem;

.header {
	inset: $header-position-y $header-position-x;
	z-index: calc(megamenu.$megamenu-z-index + 1);

	@include breakpoint-min-md {
		inset: $header-md-position-y $header-md-position-x;
	}

	// logo
	&--left {
		position: absolute;

		width: fit-content;
		height: $header-height;

		display: flex;
		align-items: center;

		transition: opacity 0.2s ease-in-out;

		&.fixed {
			position: fixed;
			opacity: 0;
			pointer-events: none;
		}
	}

	// toggle menu
	&--right {
		position: fixed;
		left: unset;

		height: fit-content;


		// transition: transform 0.4s ease-in-out;
		// animation-duration: 4s;
		animation: header-right-animation 0.8s;
	}

	@keyframes header-right-animation {
		from {
			transform: translateX(150%);
			opacity: 0;
		}

		to {
			transform: translateX(0);
			opacity: 1;
		}
	}

	&--lang {

		width: 100%;
		height: $header-height;

		position: fixed;
		left: 0;
		right: 0;
		top: 5rem;

		display: flex;
		justify-content: center;

		@include breakpoint-min-md {
			width: fit-content;

			top: inherit;
			left: unset;
			right: 10rem;
		}
	}

}