@use "./../variables/breakpoints" as *;

.p {
	&-fixed {
		position: fixed !important;
	}

	@include breakpoint-min-lg {
		&-lg {
			&-fixed {
				position: fixed !important;
			}
		}
	}
}
